export const BASE_URL = 'https://run-api.verylong.games';
export const INFURA_URL = 'https://polygon-mainnet.infura.io/v3/e45e655e40d248ff87adf995becd7768';
export const CONTRACT = '0xc52d9642260830055c986a97794b7b27393edf5e';

export const APP_BODY_ID = 'appBody';
export const APP_ROOT_ID = 'appRoot';
export const APP_CANVAS_ID = 'appCanvas';
export const SCREEN_WIDTH = 1024;
export const SCREEN_HEIGHT = 1024;

export const IMAGE_STAGE_BUSH = 'stage_bush.png';
export const IMAGE_STAGE_COIN = 'stage_coin.png';

export const SCROLL_SPEED = 10;

export const RUN_TIME = 30;
export const RUN_SPEED_LIMIT = 10;

export const IN_IFRAME = window != window.parent;

export const TOUCH_DEVICE = ( window.ontouchstart !== undefined && 0 < navigator.maxTouchPoints );
export const BASIC_ACTION = TOUCH_DEVICE ? 'Tap' : 'Click';

export const CAN_SHARE = navigator.share !== undefined;

export const BODY_COLOR_PORTRAIT = '#402334';
export const BODY_COLOR_LANDSCAPE = '#000000';
export const BODY_COLOR_LOADING = '#000000';