import {Loader, Container, Graphics, Text} from 'pixi.js';
import { sound } from '@pixi/sound';
import {BASE_URL, SCREEN_WIDTH, SCREEN_HEIGHT, RUN_TIME, RUN_SPEED_LIMIT, SCROLL_SPEED, IN_IFRAME} from 'Const';
import { RunSceneFrontLayer } from './RunSceneFrontLayer';
import { RunningAnimal } from 'objects/RunningAnimal';
import { ObstructiveAnimal } from 'objects/ObstructiveAnimal';
import { Coin } from '../objects/Coin';
import { IsCollision } from '../utils/IsCollision';
import { GetTokenImageOriginalUrl } from 'utils//GetTokenImageOriginalUrl';
import { GetTokenImageUrl } from 'utils/GetTokenImageUrl';
import { AnimalTextureParser } from 'utils/AnimalTextureParser';
import { getAPI, postAPI } from 'utils/API';
import { sha256 } from 'utils/sha256';
import { sceneManager } from 'SceneManager';
import { Tween24, Ease24 } from 'tween24';
import { TopScene } from '/scenes/TopScene';
import { Picture } from 'objects/Picture';
import bgm2 from 'assets/include/sound/bgm_2.mp3';
import bgmStop from 'assets/include/sound/bgm_stop.mp3';
import seBuff from 'assets/hash/sound/se_buff.mp3';
import seDown from 'assets/hash/sound/se_down.mp3';
import seJump from 'assets/hash/sound/se_jump.mp3';
import seShutter from 'assets/hash/sound/se_shutter.mp3';
import stageImages from 'assets/version/stage.json';
import spineCharacter from 'assets/version/animal.json';
import spineCoin from 'assets/version/coin.json';
import spineSpeedup from 'assets/version/speedup.json';

const STATE_READY = 0;
const STATE_START = 1;
const STATE_FINISH = 2;
const STATE_FAILURE = 3;
const STATE_EXIT = 4;

// const SCORE_VIEW_TIME = 2000;
const RETRY_WAIT_SEC = 10;
const BACK_HOME_TIME = 12000;

// スコアのダブルチェック用
let checkDistance = 0;

export class RunScene extends Container {
  resources(){
    return [
      ['stageImages', stageImages],
      ['spineCharacter', spineCharacter],
      ['spineCoin', spineCoin],
      ['spineSpeedup', spineSpeedup],
      ['se_buff', seBuff],
      ['se_down', seDown],
      ['se_jump', seJump],
      ['se_shutter', seShutter],
      ['bgm_2', bgm2],
      ['bgm_stop', bgmStop],
    ];
  }
  bgm(){
    return 'bgm_2';
  }
  async Init(){
    checkDistance = 0;
    this.coinCount = 0;
    this.startTimeStamp = Date.now();
    this.state = STATE_START;
    this.backContainer = new Container();
    this.addChild(this.backContainer);
    this.middleContainer = new Container();
    this.addChild(this.middleContainer);
    this.frontContainer = new RunSceneFrontLayer();
    this.addChild(this.frontContainer);

    const url = new URL(window.location.href);
    const params = url.searchParams;
    this.tokenId = params.has('tokenId') ? params.get('tokenId') : 89;
    // const originalImageUrl = await GetTokenImageOriginalUrl(this.tokenId);
    // infura エラー暫定対応
    const originalImageUrl = null; GetTokenImageOriginalUrl(this.tokenId);
    let imageUrl;
    if(originalImageUrl){
      imageUrl = await GetTokenImageUrl(this.tokenId, originalImageUrl);
    }else{
      // ブロックチェーンからURLが取れない場合（tokenIDが存在しないなど）、イモムシを選択したものとする
      // this.tokenId = 89;
      // infura エラー暫定対応
      if(this.tokenId <= 0 || 100 < this.tokenId)
      {
        this.tokenId = 89;
      }
      imageUrl = await getAPI(`/api/images/${this.tokenId}`).then(response => BASE_URL + response.url);
    }
    const runningAnimalTexture = new AnimalTextureParser();
    await runningAnimalTexture.Init(imageUrl);

    const rankersResult = await getAPI('/api/scores/rankers');
    let rankers = rankersResult.rankers.filter(ranker => ranker.tokenId != this.tokenId);
    if(rankers.length < 3){
      // ランキングTOP3までが取れない場合はランダムに取得
      const imagesRandomResult = await getAPI('/api/images/random');
      rankers = rankers.concat(imagesRandomResult.images.filter(image => !!(image.url)));
    }

    const obstructiveAnimalTextures = [];
    obstructiveAnimalTextures.push(new AnimalTextureParser());
    obstructiveAnimalTextures.push(new AnimalTextureParser());
    obstructiveAnimalTextures.push(new AnimalTextureParser());
    for(let i = 0; i < obstructiveAnimalTextures.length; i++){
      await obstructiveAnimalTextures[i].Init(BASE_URL + rankers[i].url);
      // await obstructiveAnimalTextures[i].Init(BASE_URL + '/storage/images/46b788b760474af7c955282c84de175c2e3fd5e82c1fe69824a5e347f05f7217.png');
    }

    const background = new Graphics();
    background.beginFill(runningAnimalTexture.backgroundColorCode);
    background.drawRect(0, 0, SCREEN_WIDTH, SCREEN_HEIGHT);
    background.endFill();
    background.interactive = true;
    background.buttonMode = true;
    this.speed = 1;

    const pointerdown = () => {
      if(this.enableRetry && (this.state == STATE_FINISH || this.state == STATE_FAILURE)){
        this.state = STATE_EXIT;
        sceneManager.LoadScene(RunScene);
      }
      if(this.state != STATE_START) return;
      this.animal.StartJump();
    }
    const pointerup = () => {
      if(this.state != STATE_START) return;
      this.animal.EndJump();
    }
    background.on('pointerdown', pointerdown);
    background.on('pointerup', pointerup);
    background.on('pointercancel', pointerup);
    this.backContainer.addChild(background);

    sceneManager.addBodyPointerDownListener(pointerdown);
    sceneManager.addBodyPointerUpListener(pointerup);

    this.frontContainer.Init();

    this.coins = [];
    for(let i = 0; i <= 5; i++){
      const coin = new Coin();
      if(i == 0){
        coin.Reset();
      }else{
        coin.Reset(this.coins[i-1]);
      }
      this.coins.push(coin);
      this.addChild(coin);
    }

    this.rivals = [];
    for(let i = 0; i < obstructiveAnimalTextures.length; i++){
      const rival = new ObstructiveAnimal(
        Loader.shared.resources.spineCharacter.spineData,
        obstructiveAnimalTextures[i]
      );
      rival.scale.x = 0.105;
      rival.scale.y = 0.105;
      if(i == 0){
        rival.Reset();
      }else{
        rival.Reset(this.rivals[i-1]);
      }
      rival.StartAnimation('ambush', true);
      this.rivals.push(rival);
      this.middleContainer.addChild(rival);
    }

    this.animal = new RunningAnimal(Loader.shared.resources.spineCharacter.spineData, runningAnimalTexture);
    this.animal.FixBaseParams();
    this.animal.scale.x = -0.105;
    this.animal.scale.y = 0.105;
    this.animal.position.x = -SCREEN_WIDTH * 0.1;
    this.animal.position.y = SCREEN_HEIGHT * 0.75;
    this.middleContainer.addChild(this.animal);
    this.animal.FixBaseParams();

    this.animal.StartAnimation('run', true);

    Tween24.tween(this.animal, 1, Ease24._3_CubicInOut).x(SCREEN_WIDTH * 0.1).play();

    this.startTime = performance.now();
    this.timerText = new Text(RUN_TIME, {
      fontFamily: 'Rubik Bubbles',
      fontSize: 200,
      fill: 'white',
      dropShadow: true,
      dropShadowDistance: 10,
      dropShadowAlpha: 0.25,
      dropShadowBlur: 10,
    });
    this.addChild(this.timerText);
    this.timerText.position.x = SCREEN_WIDTH * 0.5;
    this.timerText.position.y = SCREEN_HEIGHT * 0.25;
    this.timerText.anchor.set(0.5);

    this.distance = 0;
    this.distanceText = new Text('0 m', {
      fontFamily: 'Rubik Moonrocks',
      fontSize: 100,
      fill: 0xa24a55,
    });
    this.addChild(this.distanceText);
    this.distanceText.position.x = SCREEN_WIDTH * 0.5;
    this.distanceText.position.y = SCREEN_HEIGHT * 0.9;
    this.distanceText.anchor.set(0.5);
    this.distanceText.alpha = 0.6;

    this.retryText = new Text(`Continue`, {
      fontFamily: 'Rubik Moonrocks',
      fontSize: 40,
      fill: 0xffffff,
    });
    this.addChild(this.retryText);
    this.retryText.anchor.set(0, 0.5);
    this.retryText.position.x = SCREEN_WIDTH * 0.7;
    this.retryText.position.y = SCREEN_HEIGHT * 0.95;
    this.retryText.visible = false;

    this.backHomeCountText = new Text('10', {
      fontFamily: 'Rubik Moonrocks',
      fontSize: 40,
      fill: 0xffffff,
    });
    this.addChild(this.backHomeCountText);
    this.backHomeCountText.anchor.set(1, 0.5);
    this.backHomeCountText.position.x = SCREEN_WIDTH * 0.95;
    this.backHomeCountText.position.y = SCREEN_HEIGHT * 0.95;
    this.backHomeCountText.visible = false;

    this.enableRetry = false;
    this.isCountdown = false;
    this.timer = 0;
    this.latestTime = performance.now();

    this.picture = null;
  }

  UpdateScene(dt){
    const now = performance.now();
    if(this.picture){
      this.picture.UpdateScene(dt);
    }
    if(this.isCountdown){
      const elapsedTime = now - this.latestTime;
      this.timer += elapsedTime;
      const closeLimit = Math.ceil((BACK_HOME_TIME - this.timer) * 0.001);
      if(!this.enableRetry){
        if(closeLimit <= RETRY_WAIT_SEC){
          this.enableRetry = true;
          this.retryText.visible = true;
          this.backHomeCountText.visible = true;
        }
      }else{
        this.backHomeCountText.text = closeLimit;
        if(closeLimit <= 0){
          this.state = STATE_EXIT;
          sceneManager.LoadScene(TopScene);
        }
      }
    }
    this.latestTime = now;

    if(this.state == STATE_FAILURE){
      // ぶつかった場合
      this.animal.UpdateScene(dt); // ジャンプの動作は更新する（着地するように）
      if(this.animal.x < SCREEN_WIDTH * 0.45){
        this.animal.x += dt * this.speed * SCROLL_SPEED;
      }
    }else if(this.state == STATE_FINISH){
      // ゴールした場合
      // 画面スクロールは続く
      const moveDistance = dt*this.speed;
      this.frontContainer.UpdateScene(moveDistance);
      this.animal.UpdateScene(moveDistance); // ジャンプの動作は更新する（ここではスローになるようスピードを掛けた値を使う）
      if(this.animal.x < SCREEN_WIDTH * 0.45){
        this.animal.x += dt * this.speed * SCROLL_SPEED;
      }
      // ライバルはスクロールだけさせる
      for(let i = 0; i < this.rivals.length; i++){
        const rival = this.rivals[i];
        rival.UpdateScene(moveDistance);
      }
      // コインはスクロールだけさせる
      for(let i = 0; i < this.coins.length; i++){
        const coin = this.coins[i];
        coin.UpdateScene(moveDistance);
      }
    }else if(this.state == STATE_START){
      const moveDistance = dt*this.speed;
      this.frontContainer.UpdateScene(moveDistance);
      this.animal.UpdateScene(dt); // ジャンプの動作には this.speed を影響させない
      this.distance += moveDistance;
      checkDistance += moveDistance;

      const time = performance.now() - this.startTime;
      const timeLimit = RUN_TIME - Math.floor(time * 0.001);
      this.timerText.text = timeLimit;
      this.distanceText.text = `${Math.floor(this.distance * 0.1)} m`;

      if(this.state == STATE_START && timeLimit <= 0){
        // ゴール（制限時間走り続けた）判定
        this.state = STATE_FINISH;
        sceneManager.setBGM('bgm_stop', false);
        this.speed = 0.1;
        this.animal.ChangeAnimationScale(this.speed);
        this.animal.Finish();
        this.rivals.forEach(rival => {
          rival.ChangeAnimationScale(this.speed);
        });
        this.distanceText.text = `${Math.floor(this.distance * 0.1)} m`;
        this.finishProcess();
        return;
      }

      // ライバルとの当たり判定
      let lastRival = null;
      let resetRivalCount = 0;
      for(let i = 0; i < this.rivals.length; i++){
        const rival = this.rivals[i];
        rival.UpdateScene(moveDistance);
        if(rival.x <= -100){
          if(lastRival == null){
            rival.Reset(this.rivals[this.rivals.length-1])
          }else{
            rival.Reset(lastRival);
          }
          lastRival = rival;
          resetRivalCount++;
        }
        // 近寄っていたら当たり判定
        if(rival.position.x <= SCREEN_WIDTH * 0.25){
          // TODO:すり抜け判定はいずれ
          if(IsCollision(this.animal.collider, rival.collider)){
            // this.speed = 0;
            this.state = STATE_FAILURE;
            sceneManager.setBGM('bgm_stop', false);
            this.animal.Fall();
            rival.ChangeAnimationScale(2);
            rival.StartAnimation('down', false);
            sound.play('se_down');
            this.failureProcess();
            return;
          }
        }
      }
      for(let i = 0; i < resetRivalCount; i++){
        const coin = this.rivals.shift();
        this.rivals.push(coin);
      }

      // コインとの当たり判定
      let lastCoin = null;
      let resetCount = 0;
      for(let i = 0; i < this.coins.length; i++){
        const coin = this.coins[i];
        coin.UpdateScene(moveDistance);
        if(coin.x <= -100){
          if(lastCoin == null){
            coin.Reset(this.coins[this.coins.length-1])
          }else{
            coin.Reset(lastCoin);
          }
          lastCoin = coin;
          resetCount++;
        }
        // 近寄っていたら当たり判定
        if(coin.position.x <= SCREEN_WIDTH && !coin.isGetted){
          // TODO:すり抜け判定はいずれ
          if(IsCollision(this.animal.collider, coin.collider)){
            // コイン獲得処理
            coin.Getted();
            this.speed *= 1.1;
            this.coinCount++;
            if(RUN_SPEED_LIMIT <= this.speed){
              this.speed = RUN_SPEED_LIMIT;
            }else{
              this.animal.SpeedUp();
              sound.play('se_buff');
            }
            this.animal.ChangeAnimationScale(this.speed);
          }
        }
      }
      for(let i = 0; i < resetCount; i++){
        const coin = this.coins.shift();
        this.coins.push(coin);
      }
    }
  }

  async sendScore(){
    // TODO:リトライ処理
    const r = Date.now();
    const h = await sha256(
      `${this.distance + checkDistance - this.distance}${this.startTimeStamp}${this.finishTimeStamp}${r}`
    );
    const h2 = await sha256(
      `${h}${this.coinCount}`
    );

    return await postAPI(
      `/api/scores/${this.tokenId}`,
      [
        ['s', this.distance],
        ['i', this.startTimeStamp],
        ['f', this.finishTimeStamp],
        ['c', this.coinCount],
        ['r', r],
        ['h', h],
        ['2', h2],
      ],
    )
  }

  async finishProcess(){
    this.timerText.text = 'Finish!!';
    this.timerText.scale.set(0);
    Tween24.tween(this.timerText.scale, 1, Ease24._ElasticOut, {x:1, y:1}).play();
    await this.sendScoreProcess();
  }

  async failureProcess(){
    this.timerText.text = 'oops!!';
    this.timerText.scale.set(0);
    Tween24.tween(this.timerText.scale, 1, Ease24._ElasticOut, {x:1, y:1}).play();
    await this.sendScoreProcess();
  }

  async sendScoreProcess(){
    this.finishTimeStamp = Date.now();
    const result = await this.sendScore();
    if(result.rank){
      const newRecord = new Text(`#${this.tokenId} New Record!!`, {
        fontFamily: 'Rubik Bubbles',
        fontSize: 80,
        fill: 'white',
        dropShadow: true,
        dropShadowDistance: 10,
        dropShadowAlpha: 0.25,
        dropShadowBlur: 10,
      });
      this.addChild(newRecord);
      newRecord.position.x = SCREEN_WIDTH * 0.5;
      newRecord.position.y = SCREEN_HEIGHT * 0.15;
      newRecord.anchor.set(0.5);
      newRecord.scale.set(0);
  
      const rankText = new Text(`RANK ${result.rank}`, {
        fontFamily: 'Rubik Bubbles',
        fontSize: 180,
        fill: 'white',
        dropShadow: true,
        dropShadowDistance: 10,
        dropShadowAlpha: 0.25,
        dropShadowBlur: 10,
      });
      this.addChild(rankText);
      rankText.position.x = SCREEN_WIDTH * 0.5;
      rankText.position.y = SCREEN_HEIGHT * 0.3;
      rankText.anchor.set(0.5);
      rankText.scale.set(0);

      // 記録更新パターン
      Tween24.serial(
        Tween24.wait(2),
        Tween24.tween(this.timerText.scale, 0.2, Ease24._ElasticOut, {x:0, y:0}),
        Tween24.tween(newRecord.scale, 1, Ease24._ElasticOut, {x:1, y:1}),
        Tween24.tween(rankText.scale, 1, Ease24._ElasticOut, {x:1, y:1}),
        Tween24.func(() => {
          if(IN_IFRAME){
            // iframe内（Twitter上）ではシェアもダウンロードもできないので即終了
            this.isCountdown = true;
          }else{
            this.picture = new Picture(`Animal No.${this.tokenId} NewRecord!!\n${Math.floor(this.distance * 0.1)}m Rank${result.rank}`);
            this.addChild(this.picture);
            this.picture.on('close', () => {
              this.isCountdown = true;
            });
          }
        }),
      ).play();
    }else{
      this.isCountdown = true;
    }
  }
}
