import {Loader, Container, Graphics, Text, Sprite, utils, TilingSprite, BLEND_MODES} from 'pixi.js';
import {BASE_URL, SCREEN_WIDTH, SCREEN_HEIGHT, RUN_TIME, RUN_SPEED_LIMIT, BASIC_ACTION} from 'Const';
import { RunSceneFrontLayer } from './RunSceneFrontLayer';
import { RunningAnimal } from 'objects/RunningAnimal';
import { ObstructiveAnimal } from 'objects/ObstructiveAnimal';
import { Coin } from '../objects/Coin';
import { IsCollision } from '../utils/IsCollision';
import { GetTokenImageOriginalUrl } from 'utils//GetTokenImageOriginalUrl';
import { GetTokenImageUrl } from 'utils/GetTokenImageUrl';
import { AnimalTextureParser } from 'utils/AnimalTextureParser';
import { getAPI, postAPI } from 'utils/API';
import { sha256 } from 'utils/sha256';
import { sceneManager } from 'SceneManager';
import { TextInput } from 'ui/TextInput';
import { RunScene } from '/scenes/RunScene';
import { HomeAnimal } from '../objects/HomeAnimal';
import { Picture } from 'objects/Picture';
import bgm1 from 'assets/include/sound/bgm_1.mp3';
import homeBG from 'assets/hash/img/home_bg.png';
import spineCharacter from 'assets/version/animal.json';

const STATE_READY = 0;
const STATE_START = 1;
const STATE_FINISH = 2;
const STATE_FAILURE = 3;

// スコアのダブルチェック用
let checkDistance = 0;

export class TopScene extends Container {
  resources(){
    return [
      ['spineCharacter', spineCharacter],
      ['homeBG', homeBG],
      ['bgm_1', bgm1],
    ];
  }
  bgm(){
    return 'bgm_1';
  }
  async Init(){
    const url = new URL(location.href);
    const searchParams = url.searchParams;
    this.tokenId = searchParams.has('tokenId') ? searchParams.get('tokenId') : null;

    // 全画面背景
    const interactiveScreen = new Graphics();
    interactiveScreen.beginFill(0x99cdf5);
    interactiveScreen.drawRect(0, 0, SCREEN_WIDTH, SCREEN_HEIGHT);
    interactiveScreen.endFill();
    interactiveScreen.interactive = true;
    interactiveScreen.buttonMode = true;
    const onEntry = () => {
      if(textInput.value && 0 < parseInt(textInput.value)){
        history.replaceState('','',`?tokenId=${parseInt(textInput.value)}`);
      }else{
        const url = new URL(location.href);
        history.replaceState('','',url.origin);
      }
      sceneManager.LoadScene(RunScene);
    };
    interactiveScreen.on('pointerup', onEntry);
    sceneManager.addBodyPointerUpListener(onEntry);

    this.addChild(interactiveScreen);

    const rankerBgContainer = new Container();
    this.addChild(rankerBgContainer);

    const homeStage = new Sprite(utils.TextureCache['homeBG']);
    homeStage.scale.set(2);
    homeStage.y = 330;
    this.addChild(homeStage);

    const titleText = new Text('Very Long Run!!', {
      fontFamily: 'Rubik Bubbles',
      fontSize: 110,
      fill: 'white',
      dropShadow: true,
      dropShadowDistance: 10,
      dropShadowAlpha: 0.25,
      dropShadowBlur: 10,
    });
    this.addChild(titleText);
    titleText.position.x = SCREEN_WIDTH * 0.5;
    titleText.position.y = SCREEN_HEIGHT * 0.1;
    titleText.anchor.set(0.5);

    const entry = new Text(`${BASIC_ACTION} To Entry`, {
      fontFamily: 'Rubik Moonrocks',
      fontSize: 80,
      fill: 'white',
      dropShadow: true,
      dropShadowDistance: 10,
      dropShadowAlpha: 0.25,
      dropShadowBlur: 10,
    });
    this.addChild(entry);
    entry.position.x = SCREEN_WIDTH * 0.5;
    entry.position.y = SCREEN_HEIGHT * 0.83;
    entry.anchor.set(0.5);

    const rankersResult = await getAPI('/api/scores/rankers');

    this.rankerContainer = new Container();
    this.addChild(this.rankerContainer);
    const rankerPosition = [
      {x:SCREEN_WIDTH*0.5, y:368},
      {x:SCREEN_WIDTH*0.15, y:473},
      {x:SCREEN_WIDTH*0.85, y:527},
    ]
    const rankerBgPositionX = [
      337,
      -10,
      680,
    ];
    const rankerAnimalTextures = [];
    const rankerCount = rankersResult.rankers.length <= 3 ? rankersResult.rankers.length : 3;
    for(let i = 0; i < rankerCount; i++){
      const animalTextureParser = new AnimalTextureParser();
      rankerAnimalTextures.push(animalTextureParser);
      await animalTextureParser.Init(BASE_URL + rankersResult.rankers[i].url);
      const homeAnimal = new HomeAnimal(
        Loader.shared.resources.spineCharacter.spineData,
        animalTextureParser
      );
      this.rankerContainer.addChild(homeAnimal);
      homeAnimal.x = rankerPosition[i].x;
      homeAnimal.y = rankerPosition[i].y;
      homeAnimal.scale.set(0.1);
      homeAnimal.StartAnimation('dance_1', true);
      const scoreText = new Text(`${rankersResult.rankers[i].score}m`, {
        fontFamily: 'Rubik Moonrocks',
        fontSize: 60,
        fill: 0xa24a55,
      });
      this.addChild(scoreText);
      scoreText.position.x = rankerPosition[i].x;
      scoreText.position.y = rankerPosition[i].y + 100;
      scoreText.anchor.set(0.5);
      scoreText.alpha = 0.6;

      const background = new Graphics();
      background.beginFill(animalTextureParser.backgroundColorCode);
      background.drawRect(rankerBgPositionX[i], 0, 350, SCREEN_HEIGHT);
      background.endFill();
      rankerBgContainer.addChildAt(background, 0);
    }

    const textInput = new TextInput({
      // typeには'number'など、HTMLInputElementのtypeを指定できます（デフォルト'text'）
      // https://developer.mozilla.org/ja/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types
      type: 'number',
      text: this.tokenId,
      x: 350,
      y: 650,
      width: 300,
      height: 100,
      // fontのstyleを指定できます
      fontFamily: "Rubik Moonrocks", // ヒラギノ角ゴを優先し、非対応端末ではsans-serifを使用する例
      fontSize: '50px',
      fontWeight: 'bold',
      placeholder: '#89',
      textAlign : 'center',
    });
    // addChildしなくても表示されますが、シーン遷移時に自動破棄されるよういずれかのオブジェクトに紐づけてください
    this.addChild(textInput);

    // setTimeout(() => {
    //   this.addChild(new Picture());

    // }, 3000);
  }

  UpdateScene(dt){
    this.children.forEach(child => {
      if(child.UpdateScene){
        child.UpdateScene(dt)
      }
    });
  }
}
